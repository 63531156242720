"use client";
import React, { useCallback, useMemo, useState } from "react";
import {
  UserOutlined,
  LoginOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons";
import {
  Dropdown,
  Avatar,
  Typography,
  Button,
  Image,
  Divider,
  Modal,
  Select,
  Form,
  Row,
  Col,
  Grid,
} from "antd";
import { useUserContext } from "@/contexts/user.context";
import { useAppContext } from "@/contexts/app.context";
const { Text, Paragraph } = Typography;
const DELIM = ";delim;";

const EmailSelector = () => {
  const { postInfoMessage } = useAppContext();
  const { user, logout, proxyUser, resetProxyUser } = useUserContext();
  const [userModal, setUserModal] = useState({
    show: false,
  });
  const screens = Grid.useBreakpoint();
  const userNameLabel = useMemo(() => {
    return (
      <Text color='white' style={{ marginLeft: ".5rem", marginTop: ".1rem" }}>
        {user?.idToken.payload.given_name} {user?.idToken.payload.family_name}
        <Divider style={{ margin: "5px 0 5px 0" }} />
      </Text>
    );
  }, [user]);

  const items = [
    {
      key: "userlabel",
      label: userNameLabel,
      disabled: true,
    },

    {
      label: "Logout",
      key: "logout",
      icon: <LoginOutlined />,
    },
    {
      label: "Change user",
      key: "userchange",
      icon: <UserSwitchOutlined />,
    },
    {
      label: "Reset user",
      key: "userreset",
      icon: <UserOutlined />,
    },
    {
      key: "info",
      label: (
        <>
          <Divider style={{ margin: "5px 0 5px 5px" }} />
          <Paragraph style={{ fontSize: "80%" }}>
            Logged-in as: {user?.idToken.payload.email}
          </Paragraph>
          <Paragraph style={{ fontSize: "80%" }}>
            Using as: {proxyUser?.email}
          </Paragraph>
        </>
      ),
      disabled: true,
    },
  ];
  const handleMenuAction = (e) => {
    if (e.key === "logout") {
      logout();
    } else if (e.key === "userchange") {
      setUserModal((modal) => ({ ...modal, show: true }));
    } else if (e.key === "userreset") {
      resetProxyUser();
      postInfoMessage("User reset");
    } else {
      e.domEvent.stopPropagation();
      e.domEvent.preventDefault();
    }
  };

  return (
    <>
      <Dropdown
        overlayStyle={{ marginLeft: "1rem" }}
        menu={{
          items,
          onClick: handleMenuAction,
        }}
        placement='bottomRight'>
        <Button type='primary' size='large'>
          <Avatar
            size='small'
            icon={
              <Image
                preview={false}
                src={user?.idToken.payload.picture}
                alt='logo'
              />
            }
            style={{
              alignContent: "center",
              justifyContent: "center",
            }}
          />
          {/* {userNameLabel} */}
        </Button>
      </Dropdown>
      <UserSelectModal
        show={userModal.show}
        onClose={() => setUserModal((modal) => ({ ...modal, show: false }))}
      />
    </>
  );
};
export default EmailSelector;

const UserSelectModal = ({ show, onClose }) => {
  const { postInfoMessage, users } = useAppContext();
  const { updateProxyUser } = useUserContext();
  const userList = useMemo(() => users.data || []).map(
    (u) => ({
      label: `${u.email || u.eid} || ${u.doctor_type || ""}`,
      value: u.email + DELIM + u.eid,
    }),
    [users]
  );

  const handleSearch = useCallback(
    async (data) => {
      console.log(data);
      try {
        const [email, eid] = data.user.split(DELIM);
        const user = users.data.find((u) => u.eid === eid);
        updateProxyUser({ email: email, name: user.first_name });
        onClose();
        postInfoMessage("User changed.");
      } catch (error) {
        console.error(error);
      }
    },
    [users.data]
  );

  return (
    <Modal title='Change user' open={show} onCancel={onClose} footer={null}>
      <Form
        name='userchange'
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={{ user: "" }}
        onFinish={handleSearch}
        autoComplete='off'>
        <Form.Item
          label='User'
          name='user'
          style={{ margin: "20px 0 20px 0" }}
          rules={[
            {
              required: true,
              message: "Invalid Input!",
            },
          ]}>
          <Select
            showSearch
            placeholder='Select a user'
            options={userList}
            style={{ width: "100%", maxWidth: "300px" }}
            loading={users.isFetching}
          />
          {/* <Input type='text' style={{ width: "100%", maxWidth: "300px" }} /> */}
        </Form.Item>
        {/* {search.error && (
					<Space style={{ color: "red" }}>
						<ExclamationCircleOutlined style={{ marginBottom: 8 }} />
						<Text type="danger">{search.error}</Text>
					</Space>
				)} */}
        <Row justify='end' gutter={4}>
          <Col>
            <Button type='default' onClick={onClose}>
              Cancel
            </Button>
          </Col>
          <Col>
            <Button
              type='primary'
              htmlType='submit'
              disabled={users.isFetching}>
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
