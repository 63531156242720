"use client";
/* eslint-disable react-hooks/exhaustive-deps */

import {
  useContext,
  createContext,
  useState,
  useCallback,
  useEffect,
} from "react";
import {
  fetchAuthSession,
  signInWithRedirect,
  signOut,
} from "aws-amplify/auth";
import { useRouter, usePathname } from "next/navigation";
import FullPageLoader from "@/components/fullPageLoader";
import "@/config/auth.config";
import { isEmpty } from "lodash";
import * as Sentry from "@sentry/nextjs";

const UserContext = createContext();

const useUserContext = () => useContext(UserContext);

const delay = (ms) => new Promise((res) => setTimeout(res, ms));
const UserContextProvider = ({ children }) => {
  const router = useRouter();
  const pathname = usePathname();
  const [user, setUser] = useState(null);
  const [proxyUser, setProxyUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const updateProxyUser = (user) => {
    console.log("updateProxyUser", user);
    setProxyUser(
      user
        ? {
            ...user,
          }
        : user
    );
    if (!isEmpty(user))
      localStorage.setItem("b2b-proxy-user", JSON.stringify(user));
    else localStorage.removeItem("b2b-proxy-user");
  };

  const resetProxyUser = useCallback(async (signInUserSession) => {
    try {
      const { tokens } = await fetchAuthSession();
      const proxy = {
        email: tokens?.idToken.payload.email,
        name: tokens?.idToken.payload.name,
      };
      updateProxyUser(proxy);
    } catch (error) {
      console.error("Failed to reset proxy user", error);
      updateProxyUser(null);
    }
  
  }, []);
  const checkProxyUser = useCallback(async (signInUserSession) => {
    const retrivedProxy = JSON.parse(localStorage.getItem("b2b-proxy-user"));
    if (!retrivedProxy) {
     await resetProxyUser(signInUserSession);
    } else {
      updateProxyUser(retrivedProxy);
    }
  }, []);

  const checkUser = useCallback(async () => {
    try {
      const { tokens } = await fetchAuthSession();
      
      const signInUserSession = tokens;
      
      selectUser(signInUserSession);
      await checkProxyUser(signInUserSession);
      Sentry.setUser({
        email: signInUserSession?.idToken?.payload?.email,
      });
      if (signInUserSession && pathname === "/") {
        const redirectedPath = "/appointments";
        return router.replace(
          ["dezy.com", "doc32.in"].includes(
            signInUserSession?.idToken?.payload?.email?.split("@").pop()
          )
            ? redirectedPath
            : "/support-upload/search"
        );
      }
    } catch (error) {
      console.error("Failed to check user", error);
      selectUser(null);
      router.replace("/");
    } finally {
      await delay(500);
      setLoading(false);
    }
  }, [checkProxyUser]);

  useEffect(() => {
    checkUser();  
  }, [checkUser]);

  const selectUser = useCallback((user) => {
    setUser(user);
  }, []);

  const handleLogin = useCallback(async () => {
    try {
      setLoading(true);
      await signInWithRedirect({
        provider: "Google",
      });
      await checkUser();
      await resetProxyUser();
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  }, []);

  const logout = useCallback(async () => {
    try {
      updateProxyUser(null);
      await signOut({
        global: true,
      });
      selectUser(null);
      console.log("Logged out");
      router.replace("/");
    } catch (error) {
      console.error("Logout failed", error);
    }
  }, []);

  if (loading) {
    return <FullPageLoader />;
  }
  return (
    <UserContext.Provider
      value={{
        user,
        loading,
        selectUser,
        checkUser,
        handleLogin,
        logout,
        proxyUser,
        updateProxyUser,
        resetProxyUser,
      }}>
      {children}
    </UserContext.Provider>
  );
};

export { useUserContext };
export default UserContextProvider;
