'use client';

import { useUserContext } from '@/contexts/user.context';
import { FileImageOutlined, HddOutlined, HomeOutlined, UserOutlined, WechatOutlined } from '@ant-design/icons';
import { App, Button, Grid, Layout, Menu, Typography } from 'antd';
import Image from 'next/image';
import Link from 'next/link';
import { usePathname, useRouter } from 'next/navigation';
import { useMemo } from 'react';
import EmailSelector from '../app/appointments/_components/emailSelector';

const { Content, Sider } = Layout;

const { Title, Paragraph } = Typography;

function getItem(label, key, icon, children) {
	return {
		key,
		icon,
		children,
		label,
	};
}
const items = [
	getItem('Appointments', '/appointments', <HddOutlined />),
	getItem('Patient Profile', '/patient/fetch', <UserOutlined />),
	getItem('Clinic', '/clinic', <HomeOutlined />),
	getItem('PPR', '/ppr', <FileImageOutlined />),
	getItem('Help', '/help', <WechatOutlined />),
];

const { useBreakpoint } = Grid;
function DashboardLayout({ children }) {
	const router = useRouter();
	const pathName = usePathname();
	const { user, loading, proxyUser, handleLogin } = useUserContext();
	const screens = useBreakpoint();

	const activeKey = useMemo(() => items.find((item) => pathName.startsWith(item.key))?.key, [pathName]);

	if (loading || !user || pathName === '/') {
		return (
			<App>
				<Content
					style={{
						minHeight: '100vh',
						backgroundColor: 'white',
						alignItems: 'center',
						justifyContent: 'center',
						display: 'flex',
						flexDirection: 'column',
					}}>
					<Title level={3} className='text-[#361272] text-center'>
						Smiles come easy with Dezy
					</Title>
					<Paragraph className='text-center'>
						The Perfect Smile correction, <br />
						from the comfort of your Home
					</Paragraph>
					<Button
						type='default'
						size='large'
						shape='round'
						onClick={handleLogin}
						style={{
							backgroundColor: '#AF78FE',
							color: 'white',
						}}>
						Login with Google
					</Button>
				</Content>
			</App>
		);
	}

	// console.log(pathName);
	// if (pathName.search('support-upload')) {
	// 	return (
	// 		<div>
	// 			<Content
	// 				style={{
	// 					overflow: 'initial',
	// 					minHeight: 'calc(100% - 90px)',
	// 				}}>
	// 				{children}
	// 			</Content>
	// 		</div>
	// 	);
	// }

	return (
		<Layout hasSider style={{ minHeight: '100%' }}>
			<Sider
				theme='dark'
				width={230}
				// collapsible
				style={{
					// overflow: "auto",
					height: '100vh',
					position: 'fixed',
					width: 230,
					left: 0,
					top: 0,
					bottom: 0,
					zIndex: 11,
					boxShadow: !screens.lg && '1px 0 10px 0px #777',
				}}
				zeroWidthTriggerStyle={{ top: '1rem' }}
				breakpoint='lg'
				collapsedWidth='0'>
				{pathName.includes('support-upload') || pathName.includes('ticket-upload') ? (
					<Image
						src='/images/dezy_logo.png'
						alt='logo'
						width={80}
						height={80}
						style={{
							marginLeft: '2rem',
							marginTop: '1.5rem',
							marginBottom: '.5rem',
						}}
					/>
				) : (
					<Link href='/appointments'>
						<Image
							src='/images/dezy_logo.png'
							alt='logo'
							width={80}
							height={80}
							style={{
								marginLeft: '2rem',
								marginTop: '1.5rem',
								marginBottom: '.5rem',
							}}
						/>
					</Link>
				)}
				{!pathName.includes('support-upload') && !pathName.includes('ticket-upload') && (
					<Menu
						theme='dark'
						selectedKeys={[activeKey]}
						defaultSelectedKeys={[activeKey]}
						mode='inline'
						items={items}
						onClick={(e) => {
							try {
								console.log(e.key);
								router.push(e.key, { scroll: false });
							} catch (error) {
								console.error('navigation error', error);
							}
						}}
					/>
				)}
				<div
					className='shadow-lg'
					style={{
						position: 'fixed',
						bottom: '1rem',
						left: '1rem',
					}}>
					<EmailSelector />
				</div>
			</Sider>

			<Layout
				style={{
					marginLeft: screens.lg ? 230 : 'unset',
					height: '100%',
				}}>
				{/* <Button
          type='text'
          icon={
            collapsed ? (
              <MenuUnfoldOutlined color='#fff' />
            ) : (
              <MenuFoldOutlined color='#fff' />
            )
          }
          onClick={() => setCollapsed(!collapsed)}
        style={{ marginLeft: screens.lg ? 230 : 'unset', height: '100%' }}
      >
        {/* <Header
          style={{
            fontSize: "16px",
            background: "#001529",
            color: "#fff",
            borderRadius: "0 5px 5px 0",
            height: "40px",
            width: "40px",
            zIndex: 12,
            left: collapsed ? 0 : 230,
            position: "absolute",
          }}
        /> */}

				<Content
					style={{
						overflow: 'initial',
						minHeight: 'calc(100% - 90px)',
					}}>
					{children}
				</Content>
			</Layout>
		</Layout>
	);
}

export default DashboardLayout;
