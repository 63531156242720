export const ENPOINTS = {
  dev: "https://doc32.xyz/api",
  prod: "https://smiles.ai/api",
};

export const HOST = {
  local: "http://localhost:3000/",
  dev: "https://crmdev.dezy.com/",
  prod: "https://crm.dezy.com/",
};

export const BACKEND_URL = {
  prod: "https://backend.doc32.com",
  dev: "https://backend.doc32.xyz",
};

export const WEBSITE_URL = {
  prod: "https://www.dezy.com",
  dev: "https://dev.dezy.com",
  local: "https://dev.dezy.com",
};

export const STAGE =
  process.env.NEXT_PUBLIC_ENVIRONMENT === "prod" ? "prod" : "dev";

export const isDev = STAGE === "dev";
export const isProd = STAGE === "prod";
