"use client";

import React, { useState } from "react";
import { useServerInsertedHTML } from "next/navigation";
import { StyleProvider, createCache, extractStyle } from "@ant-design/cssinjs";

export default function StyledComponentsRegistry({ children }) {
  const [cache] = useState(() => createCache());

  useServerInsertedHTML(() => (
    <style
      id='antd'
      dangerouslySetInnerHTML={{ __html: extractStyle(cache, true) }}></style>
  ));

  return <StyleProvider cache={cache}>{children}</StyleProvider>;
}
