"use client";

import { getActiveEmployees } from "@/apis/dynamo";
import usePageQuery from "@/app/_hooks/usePageQuery";
import { message } from "antd";
import { createContext, useContext, useCallback } from "react";

/**
 * Context for the application.
 */
const AppContext = createContext();

/**
 * Custom hook to use the AppContext.
 * @returns {Object} The context value.
 */
const useAppContext = () => useContext(AppContext);

/**
 * Provider component for AppContext.
 * @param {Object} props - Props for AppContextProvider.
 * @param {React.ReactNode} props.children - Children elements of the provider.
 */
const AppContextProvider = ({ children }) => {
  const [messageApi, contextHolder] = message.useMessage();
  const users = usePageQuery(getActiveEmployees);

  /**
   * Function to post an informational message.
   */
  const postInfoMessage = messageApi.info;

  /**
   * Function to post an error message.
   */
  const postErrorMessage = messageApi.error;

  /**
   * Function to post a success message.
   */
  const postSuccessMessage = messageApi.success;

  /**
   * Function to copy text to the clipboard and show a success message.
   * @param {string} text - Text to be copied to clipboard.
   */
  const copyToClipboard = useCallback(
    async (text) => {
      await navigator.clipboard.writeText(text);
      postSuccessMessage(`Copied ${text} to clipboard`);
    },
    [postSuccessMessage]
  );

  return (
    <AppContext.Provider
      value={{
        copyToClipboard,
        postInfoMessage,
        postErrorMessage,
        postSuccessMessage,
        users,
      }}>
      {contextHolder}
      {children}
    </AppContext.Provider>
  );
};

export { useAppContext };
export default AppContextProvider;
