import { Amplify } from 'aws-amplify';
import { BACKEND_URL, HOST } from '@/config/backend';

const environment = process.env.NEXT_PUBLIC_ENVIRONMENT;

const libraryOptions = {
  Storage: {
    S3: {
      prefixResolver: async ({ accessLevel, targetIdentityId }) => '',
    },
  },
};

Amplify.configure(
  {
    Auth: {
      Cognito: {
        region: 'ap-south-1',
        loginWith: 'email',
        userPoolId: process.env.NEXT_PUBLIC_USERPOOLID,
        userPoolClientId: process.env.NEXT_PUBLIC_USERPOOLWEBCLIENTID,
        identityPoolId: process.env.NEXT_PUBLIC_IDENTITYPOOLID,
        loginWith: {
          oauth: {
            domain: process.env.NEXT_PUBLIC_OAUTHDOMAIN,
            scopes: ['email', 'openid', 'profile'],
            redirectSignIn: [`${HOST[environment]}`],
            redirectSignOut: [`${HOST[environment]}`],
            responseType: 'code',
          },
        },
      },
    },
    Storage: {
      S3: {
        bucket: 'data-doc32',
        region: 'ap-south-1',
      },
    },
    API: {
      REST: {
        backend_dev: {
          endpoint: BACKEND_URL.dev,
          region: 'ap-south-1',
        },
        backend_prod: {
          endpoint: BACKEND_URL.prod,
          region: 'ap-south-1',
        },
        backend_local: {
          endpoint: BACKEND_URL.dev,
          region: 'ap-south-1',
        },
      },
    },
    ssr: true,
  },
  libraryOptions,
);

// Amplify.Logger.LOG_LEVEL = "DEBUG";
console.log('Amplify Init done');
