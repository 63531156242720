import React from "react";
import { Spin, Card } from "antd";

const FullPageLoader = () => {
  return (
    <Card className='flex justify-center w-full h-screen items-center'>
      <Spin size='large' />
    </Card>
  );
};

export default FullPageLoader;
